import * as React from 'react';
import * as CssModule from './Layout.module.css';

import Art from './Art';

import ArtRat from '../images/arts/zodiac_1/01_Rat.png';
import ArtOx from '../images/arts/zodiac_1/02_Ox.png';
import ArtTiger from '../images/arts/zodiac_1/03_Tiger.png';
import ArtRabbit from '../images/arts/zodiac_1/04_Rabbit.png';
import ArtDragon from '../images/arts/zodiac_1/05_Dragon.png';
import ArtSnake from '../images/arts/zodiac_1/06_Snake.png';
import ArtHorse from '../images/arts/zodiac_1/07_Horse.png';
import ArtGoat from '../images/arts/zodiac_1/08_Goat.png';
import ArtMonkey from '../images/arts/zodiac_1/09_Monkey.png';
import ArtRooster from '../images/arts/zodiac_1/10_Rooster.png';
import ArtDog from '../images/arts/zodiac_1/11_Dog.png';
import ArtPig from '../images/arts/zodiac_1/12_Pig.png';

import ThumbRat from '../images/thumbs/zodiac_1/01_Rat.png';
import ThumbOx from '../images/thumbs/zodiac_1/02_Ox.png';
import ThumbTiger from '../images/thumbs/zodiac_1/03_Tiger.png';
import ThumbRabbit from '../images/thumbs/zodiac_1/04_Rabbit.png';
import ThumbDragon from '../images/thumbs/zodiac_1/05_Dragon.png';
import ThumbSnake from '../images/thumbs/zodiac_1/06_Snake.png';
import ThumbHorse from '../images/thumbs/zodiac_1/07_Horse.png';
import ThumbGoat from '../images/thumbs/zodiac_1/08_Goat.png';
import ThumbMonkey from '../images/thumbs/zodiac_1/09_Monkey.png';
import ThumbRooster from '../images/thumbs/zodiac_1/10_Rooster.png';
import ThumbDog from '../images/thumbs/zodiac_1/11_Dog.png';
import ThumbPig from '../images/thumbs/zodiac_1/12_Pig.png';

const arts = [
    { art: ArtRat, thumb: ThumbRat }, { art: ArtOx, thumb: ThumbOx }, 
    { art: ArtTiger, thumb: ThumbTiger }, { art: ArtRabbit, thumb: ThumbRabbit }, 
    { art: ArtDragon, thumb: ThumbDragon }, { art: ArtSnake, thumb: ThumbSnake }, 
    { art: ArtHorse, thumb: ThumbHorse }, { art: ArtGoat, thumb: ThumbGoat }, 
    { art: ArtMonkey, thumb: ThumbMonkey }, { art: ArtRooster, thumb: ThumbRooster }, 
    { art: ArtDog, thumb: ThumbDog }, { art: ArtPig, thumb: ThumbPig }
];

const ArtZodiac1 = (props) => (
    <div className='container mb-4'>
        <div className='row'>
            <div className='col-12'>
                <h2 className={CssModule.xTitle}>Zodiac Animal Series</h2>
            </div>
        </div>
        <div className='row'>
            {arts.map((a, i) => (<Art src={a.art} thumbSrc={a.thumb} key={`ArtZodiac1_${i}`} id={`ArtZodiac1_${i}`} />))}
        </div>
    </div>
);

export default ArtZodiac1;