import * as React from "react"
import Layout from "../components/Layout";
import ArtZodiac1 from "../components/ArtZodiac1";

// markup
const IndexPage = () => (
    <Layout>
        <ArtZodiac1 />
    </Layout>
);

export default IndexPage;
