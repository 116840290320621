import * as React from 'react';
import * as CssModule from './Layout.module.css';
import ImgLogo from '../images/logo.png';

const NavBar = () => (
    <div className='text-center p-3 mb-4'>
        <img src={ImgLogo} alt='ODIQ' className={CssModule.xImgLogo} />
    </div>
);

const Layout = (props) => (
    <div className={`position-relative ${CssModule.xFullPage}`}>
        <NavBar />

        <div className='container'>
            <div className='row'>
                <div className='col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3'>
                    {props.children}
                </div>
            </div>
        </div>
    </div>
);

export default Layout;