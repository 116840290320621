import * as React from 'react';
import * as CssModule from './Layout.module.css';

const Art = (props) => (
    <div className='col-4 col-xl-3'>
        <img src={props.thumbSrc} 
             alt={props.id}
             className={`mb-3 shadow w-100 ${CssModule.xClickable}`}
             data-bs-toggle='modal'
             data-bs-target={`#${props.id}`}
        />

        <div className='modal fade' id={props.id}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                    <div className='modal-body'>
                        <img src={props.src} alt={props.id} className='w-100' />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Art;